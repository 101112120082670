@use "../../variables";
@use "../../mixins";

.discount__original {
    font-size: variables.$font-size-sm;
    text-decoration: line-through;
    color: variables.$gray-500;
    font-weight: variables.$font-weight-bold;
    display: flex;
    justify-content: flex-end;
    &__loyal{
        text-decoration: none !important;
        color: variables.$gray-500;
        font-weight: variables.$font-weight-bold;
        justify-content: flex-start;
    }
    @if variables.$site-nova-or-avi {
        &{
            color: variables.$neutral;
        }
    }
}

#lease-calculator{
    .fa-chevron-left {
        color: variables.$secondary;
    }
    
    .back{
        background: variables.$white;
        
        .button__text{
            font-weight: normal;
        }  
    } 

    .panel-right, .panel{
        background: variables.$neutral-lighter-25;
    }
    
    #leaseInformationBottom {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        
        span{
            color: variables.$neutral;
            width: 70%;
            text-align: center;
        }
    }
    .tabs-menu__item{
        min-width: 45%;
    }
    @include mixins.rental-periods;
}

#sb-lizingas-calculator {
    text-align: center;
    iframe{
        width: 70% !important;
        min-width: 400px;
        margin: 10px auto;
    }
}

.calculator {
    &__monthly-payment {
        &__container {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & {
            color: variables.$primary;
            font-size: variables.$font-size-xl;
            font-weight: variables.$font-weight-bold; 
        }
    }

    &__primary {
        color: variables.$primary;
        font-weight: variables.$font-weight-normal;
        font-size: variables.$font-size-lg;
        padding: 20px 0;
    }

    &__highlight {
        background: variables.$neutral-lighter-25;
        padding: 16px 0;
        display: flex;
        flex-direction: column;
        &--upper {
            border-bottom: 1px solid variables.$neutral-lighter-50;
        }
        &--lower {
            width: 70%;
            align-self: center;
            .highlight-section {
                display: flex;
                flex-direction: column;
                padding-top: 20px;
                
                .dropdown {
                    background: variables.$white;
                    border-radius: 8px;
                    height: 50px;
                    
                    &__selected{
                        display: flex;
                        align-items: center;
                        height: inherit;
                        padding: 3.5px 3.5px 0 6.5px;
                    }
                    span, li {
                        color: variables.$primary;
                    }
                }
            }
            
            span{
                padding-bottom: 5px;
            }
            
            .primary{
                color: variables.$primary;
            }
        }
        > span, button span:not(.calculator__monthly-payment) {
            color: variables.$neutral-dark;
        }
    }

    &__info {
        display: flex;
        width: 100%;
        justify-content: space-around;
        margin: 8px 0;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__buttons {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        row-gap: 5px;
        column-gap: 5px;
        padding: 8px 0;

        .button {
            &__text {
                white-space: nowrap;
                font-size: variables.$font-size-lg;
            }

            &:not(.button--active) {
                font-weight: variables.$font-weight-normal;
                border: 1px solid variables.$neutral-lighter;
                background: variables.$white;

                &:hover {
                    background: variables.$neutral-lighter-50;
                }
            }

            &--active {
                background: variables.$primary-lighter-10;
                font-weight: variables.$font-weight-bold;
                color: variables.$primary;
                border: 1px solid variables.$primary;

                .button__text {
                    color: variables.$primary;
                }
            }
        }
    }

    &__description {
        padding: 5px;
        color: variables.$neutral;
    }
    
    &__smart-rent-links{
        margin-top: 30px;
        margin-bottom: 40px;
    }
}

@media(min-width: variables.$responsive-break) {
    .product__review {
        .pricing-block {
            display: none;
        }
    }
    .pricing-block {
        border: solid 1px variables.$neutral-lighter-100;
        border-radius: variables.$border-radius-round;
        max-width: 280px;
        position: relative;
        .label{
            text-align: center;

            @if (variables.$site-nova) {
                color: variables.$supporting-01;
            } @else {
                color:  variables.$orange-700;
            }
        }
        
        .price {
            display: grid;
            justify-content: center;
            grid-template-areas:
                "price"
                "unit-price"
                "discount";
            @if variables.$site-nova-or-avi {
                grid-template-areas:
                "price discount"
                "unit-price discount";
            }
            
            &__original {
                grid-area: price;
            }
            .discount {
                grid-area: discount;
                @if variables.$site-nova-or-avi {
                    margin-left: 8px;
                    margin-top: -6px;
                }
            }
            
            &__unit-price {
                grid-area: unit-price;
                text-align: center;
            }
        }

        &__title {
            margin-top: 0;
            margin-bottom: 8px;
            font-size: variables.$font-size-sm;
            color: variables.$neutral-darker;
        }

        .monthly-payment {
            font-size: variables.$font-size-sm;
            margin: 0;
            margin-bottom: 8px;
        }

        &__inner--discount {
            .monthly-payment,
            .price-original {
                color: variables.$supporting-01;
            }

            .pricing-block__title {
                display: none;
            }

            .pricing-block__title--discount {
                display: block;
            }
        }

        .cart {
            margin-top: 24px;
        }

        .calculator {
            margin-bottom: 0;
            border-bottom: none;
        }

        .separator {
            border-bottom: solid 1px variables.$neutral-lighter-100;
            margin: 0 0 8px 0;
        }

        .add-to-cart {
            font-weight: variables.$font-weight-bold;
            width: 100%;
            margin-top: 10px;
        }

        svg.rating text {
            fill: variables.$white;
            font-weight: variables.$font-weight-bold;
            font-family: variables.$font-family;
            font-size: variables.$font-size-xl;
        }

        .rating {
            left: 0;
            top: 48px;
            width: 84px;
        }

        .rating-6 {
            fill: #00A651;
        }

        .rating-5 {
            fill: #50B848;
        }

        .rating-4 {
            fill: #BFD730;
        }

        .rating-3 {
            fill: #FFF200;
        }

        .rating-2 {
            fill: #FDB913;
        }

        .rating-1 {
            fill: #F37021;
        }

        .rating-0 {
            fill: #ED1C24;
        }

        .rating-a {
            fill: #00a651;
        }

        .rating-b {
            fill: #51b848;
        }

        .rating-c {
            fill: #bed731;
        }

        .rating-d {
            fill: #fcee23;
        }

        .rating-e {
            fill: #fcb918;
        }

        .rating-f {
            fill: #f37021;
        }

        .rating-g {
            fill: #ed1c24;
        }

        .discount-price-wrapper {
            padding: 10px;
            @if variables.$site-nova-or-avi{
                padding: 10px 0 0 0;
        }
        }
        
        &__lower{
            padding: 0 24px 10px 24px;
            &.discount {
                padding: 0 24px;
                @if variables.$site-nova-or-avi{
                    padding: 0 24px 10px 24px;
                }
            }
        }
    }

    .calculator {
        border-bottom: solid 1px variables.$neutral-lighter-100;


        &__button {
            padding: 0;
            width: 100%;
            border-radius: 0;

            .button__text {
                font-size: variables.$font-size-sm;
                font-weight: variables.$font-weight-bold;
            }


            &:focus {
                color: variables.$primary;
            }
        }
    }

    .price {
        &__discount {
            display: flex;

            .price__original,
            .price__unit-price {
                color: variables.$supporting-01;
                .decimal{
                    font-size: 50%;
                    @if variables.$site-nova-or-avi {
                        vertical-align: top;
                    }
                }
            }
        }

        &__original {
            font-size: variables.$font-size-3xl;
            font-weight: variables.$font-weight-bold;
            color: variables.$neutral-darker;

            &__discount {
                font-size: variables.$font-size-xs;
                margin-left: 16px;
            }
        }
    }

    .discount__original {
        font-size: variables.$font-size-sm;
        text-decoration: line-through;
        font-weight: variables.$font-weight-bold;
        display: flex;
        justify-content: center;
        color: variables.$gray-500;

        @if variables.$site-nova-or-avi {
            font-weight: variables.$font-weight-normal;
        }
    }


    .pricing-block--dark {
        background-color: variables.$neutral-darker;

        .pricing-block__title,
        .monthly-payment,
        .text,
        .input-field__label,
        .dropdown__label {
            color: variables.$white !important;
        }

        .dropdown__selected {
            background-color: variables.$white;
        }

        .calculator__button {

            .button__text {
                color: variables.$white;
            }

            &:hover {
                background-color: transparent;
            }
        }

        .price__original {
            color: variables.$supporting-01;
        }
    }

    .pricing-block--secondary {
        background-color: variables.$secondary;


        .pricing-block__campaign {
            position: absolute;
            right: 0;
            top: -50px;
        }

        .pricing-block__title,
        .monthly-payment,
        .text,
        .input-field__label,
        .dropdown__label {
            color: variables.$neutral-darker !important;
        }

        .services-list__item .icon {
            color: variables.$white;
        }

        .dropdown__selected {
            background: variables.$white;
        }

        .openModal {
            background: variables.$neutral-darker;

            .button__text,
            .icon {
                color: variables.$white;
            }
        }

        .tooltip__button {

            .icon {
                color: variables.$neutral;
            }
        }

        .calculator__button {
            background: transparent;

            .button__text {
                color: variables.$neutral-darker;
            }

            &:hover {
                background-color: transparent;
            }
        }
    }

    .energy-link {
        font-weight: 700;
    }

    .energy-container {
        display: flex;
        align-items: center;
        margin: 4px 24px 15px 24px;
        :last-child {
            margin-left: auto;
        }
    }
}

@media(max-width: variables.$responsive-break - 1) {
    #sb-lizingas-calculator iframe{
        width: 100% !important;
        min-width: unset;
    }
    
    .discount__original {
        @if variables.$site-nova {
            display: block;
            font-weight: variables.$font-weight-normal;
            color: variables.$neutral-dark;
        }
    }
    
    .pricing-block {
        #lease-calculator #leaseInformationBottom {
            height: unset;
        }

        &__bottom {
            position: fixed;
            inset: auto 0 0 0;
            z-index: variables.$zindex-positive;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            background: variables.$white;
            box-shadow: variables.$top-shadow;
            
            .label{
                display: none;
            }
        }

        .rating {
            position: absolute;
            width: 72px;
            right: 8px;
            top: 320px;
        }

        .add-to-cart {
            margin: 10px 0;
        }

        .product-link {
            padding-left: 8px;
        }
        
        .price__unit-price {
            text-align: right;
            font-size: 12px;
            line-height: 15px;            
        }

        .price__discount .price__original, {
            color: variables.$supporting-01;
        }
        
        .price__discount .price__unit-price {
            color: variables.$supporting-01;
        }        

        .price__original {
            font-size: variables.$font-size-xl;
            font-weight: variables.$font-weight-bold;
        }
        
        .responsive-panel{
            min-height: 60vh;
        }

        .label {
            @if (variables.$site-nova) {
                color: variables.$supporting-01;
            } @else {
                color:  variables.$orange-700
            }
        }
        .discount-label{
            display:none;
        }
        
        .discount_original__loyal {
            color: variables.$gray-500;
            font-weight: variables.$font-weight-bold;
            text-decoration: none;
        }
    }

    .energy-container {
        padding-top: 20px;
    }

    .code-brand-price {
        justify-content: space-around;
    }
    .overview-producer {
        .vertical-separator {
            display: none;
        }

        &__code, &__info {
            .label {
                display: none;
            }
        }
    }
}

@if variables.$site-nova {
    .pricing-block .novanaut {
        &-head, &-head-best-price, &-head-percent, &-head-percent-curved-line {
            width: 64px;
        }

        &-message {
            border-radius: 6px;
            background: variables.$color-lavender;
            color: variables.$white;
            padding: 8px 12px;
            position: relative;
            margin-left: 10px;

            &::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                top: 50%;
                left: -8px;
                border-top: 8px solid transparent;
                border-bottom: 8px solid transparent;
                border-right: 8px solid variables.$color-lavender;
                margin-top: -8px;
            }
        }
    }

    .pricing-block svg.rating text {
        letter-spacing: -2px;
    }

    @media(max-width: variables.$responsive-break - 1) {
        .pricing-block{
            .best-price-block{
                display: none;
            }
        }
    }
}