$energy-labels: "annualenergyconsumption", "annualwaterconsumption", "annual_energy_consumption", "capacitystandardwinebottles", "carpetcleaningperformance", "cyclelength", "dryereleccondens", "dryerelecvent", "dryergaz", "dryingclass", "dryingclassdish", "dustreemissionclass", "energyconsumptionregular", "energyconsumptionwithfan", "energyrating", "energy_rating", "freezerstars1", "freezerstars2", "freezerstars3", "freezerstars4", "freezer_capacity", "fridgecompartmentcapacity", "fridge_compartment_capacity", "greasefilteringefficiencyclass", "hardfloorcleaningperformance", "lightingefficiencyclass", "loadingcapacity", "maxloadcapacity", "noiselevel", "noiseleveldrying", "noiselevelwashing", "noise_level", "ovencapacity", "ovenelectric", "ovengaz", "performanceclass", "power", "powerswitch_off", "powerswitch_on", "screensize", "spinperformanceclass", "suctionefficiencyclass";

@each $label in $energy-labels {
    .energylabel-#{$label} {
        padding-left: 80px;
        margin-bottom: 30px;
        background-image: url(/images/energy/#{$label}.png);
        background-repeat: no-repeat;
        text-align: justify;
    }
}

  