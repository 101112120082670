@use '../../variables';

@media(max-width: variables.$responsive-break - 1){
    .footer{
        margin-bottom:68px;
    }

    .comparison-footer__container {
        bottom: 68px;
    }
}