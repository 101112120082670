@use '../../variables';

.product-header {
    .container {
        .breadcrumbs {
            padding-top: 30px
        }
        .product__title {
            display: block;
            margin: 14px 0 24px;
            font-size: variables.$font-size-2xl;
            font-weight: variables.$font-weight-normal;
        }
    }
    background-color: variables.$neutral-lighter-25;
    border-bottom: 1px solid variables.$neutral-lighter-50;
}

@media (max-width: variables.$responsive-break - 1) {
    .product-header {
        display: none;
    }
}